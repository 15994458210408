module.exports = [{
      plugin: require('/Users/johanneseslage/Desktop/Web/dev-website/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/Users/johanneseslage/Desktop/Web/dev-website/src/components/Layout/Layout.js"}},
    },{
      plugin: require('/Users/johanneseslage/Desktop/Web/dev-website/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/johanneseslage/Desktop/Web/dev-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
